code {
  overflow-x: auto;
  width: 100%;
  display: block;
  line-height: 2.5rem;
  background: black;
  border-radius: 5px;
  padding: 0 1rem;
  box-sizing: border-box;
}

.auth-in-a-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .small-prompt {
    margin-top: 1rem;
    display: block;
  }

  .the-box {
    background: #313131;
    border-radius: 10px;
    box-shadow: 0 6px 22px 0 #00000040;
    max-width: 95%;
    width: 450px;
    padding: 3rem;

    header {
      display: flex;
      margin-bottom: 3rem;

      img {
        width: 150px;
        margin: 0 auto;
      }
    }

    .body {
      text-align: center;

      > p {
        line-height: 1.8em;
      }

      a.button {
        color: white;
        text-decoration: none !important;
      }

      .button {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        background: #454545;
        border-radius: 5px;
        box-shadow: 0 0 0 0 #00000040;
        transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

        &.success {
          background: #78a057;

          &:hover {
            background-color: #7bae51;
          }
        }

        &.pushed-top {
          margin-top: 3rem;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: #575757;
          transform: translateX(0.3rem) translateY(-0.3rem);
          box-shadow: -0.3rem 0.3rem 5px 0 #00000040;
        }
      }
    }
  }
}

a {
  color: rgb(236, 80, 70);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: rgb(255, 63, 51);
  }
}

footer {
  padding: 1rem 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);

  span {
    padding: 1rem;
  }
}
